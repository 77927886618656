:root {
  --color-yellow: #ffe175;
  --color-light-blue: #ddf3f2;
  --color-purple: #3d2c3e;
  --color-dark-gray: #363636;
  --color-sky-blue: #a4e9e5;
  --color-para: #464646;
  --theme-yellow: #ffe175;
  --theme-light-seagreen: #009e92;
  --theme-green: #004540;
  --cream-white: #fffcf1;
  --grey-type-theme: #707070;
}

::-webkit-scrollbar {
  width: 8px;
  /* Scroll bar ka width */
}

::-webkit-scrollbar-thumb {
  background-color: var(--theme-green);
  /* Scroll bar thumb ka color */
}

/* Mozilla Firefox */
::-moz-scrollbar {
  width: 12px;
}

::-moz-scrollbar-thumb {
  background-color: var(--theme-green);
}

/* Internet Explorer */
::-ms-scrollbar {
  width: 8px;
}

::-ms-scrollbar-thumb {
  background-color: var(--theme-green);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f4f2ee;
}

/* Font Faces  */
@font-face {
  font-family: Montserrat-Thin;
  src: url(../src/assets/fonts/Montserrat-Thin.ttf);
}

@font-face {
  font-family: Montserrat-Light;
  src: url(../src/assets/fonts/Montserrat-Light.ttf);
}

@font-face {
  font-family: Montserrat-Regular;
  src: url(../src/assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: Montserrat-Medium;
  src: url(../src/assets/fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url(../src/assets/fonts/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(../src/assets/fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: Montserrat-ExtraBold;
  src: url(../src/assets/fonts/Montserrat-ExtraBold.ttf);
}

@font-face {
  font-family: Montserrat-Black;
  src: url(../src/assets/fonts/Montserrat-Black.ttf);
}

@font-face {
  font-family: AltoneTrial-Regular;
  src: url(../src/assets/fonts/AltoneTrial-Regular.ttf);
}

@font-face {
  font-family: AltoneTrial-Bold;
  src: url(../src/assets/fonts/AltoneTrial-Bold.ttf);
}

@font-face {
  font-family: Poppins-Thin;
  src: url(../src/assets/fonts/Poppins/Poppins-Thin.ttf);
}

@font-face {
  font-family: Poppins-Light;
  src: url(../src/assets/fonts/Poppins/Poppins-Light.ttf);
}

@font-face {
  font-family: Poppins-Regular;
  src: url(../src/assets/fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins-Medium;
  src: url(../src/assets/fonts/Poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(../src/assets/fonts/Poppins/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: Poppins-Bold;
  src: url(../src/assets/fonts/Poppins/Poppins-Bold.ttf);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mic-cheat {
  color: black;
  font-size: 20px;
}

span.onlineClass {
  width: 15px;
  height: 15px;
  display: block;
  position: absolute;
  right: 0;
  background: #00ff11;
  border-radius: 20px;
}

.capitalize-first-letter {
  text-transform: lowercase;
}

.pdf-filemsg p {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.capitalize-first-letter::first-letter {
  text-transform: uppercase !important;
}


.chat-right .css-f9a3wa {
  background-color: #EEEEEE !important;
  background: #EEEEEE !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.searchbuttonhit {
  position: absolute;
  right: -4px;
  height: 100%;
  border-radius: 40px;
  width: 30%;
}

.newmessageusers.hidden {
  display: none;
}

.sendmessagebtn {
  background: #00443f;
  position: absolute;
  right: 0;
  height: 100%;
  width: 50px;
  border-radius: 0px 10px 10px 0px;
}

.direct-chat-start-parent {
  overflow-y: scroll;
  height: 280px;
}

button.sendmessagebtn svg {
  color: white;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.chat-right.full-width-right-chat {
  width: 80%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*  */
.bg-darkgreen {
  background-color: #00443f;
}

.search-sidebar-chat-parent {
  padding: 0.5rem 0;
  margin-top: 1rem;
  border-top: 1px solid #ffffff7a;
  border-bottom: 1px solid #ffffff7a;
}

.search-sidebar-chat {
  width: 80%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.search-icon-leftbar-chat {
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.search-sidebar-chat-input {
  width: 100%;
  color: #fff;
  /* padding-left: 2.25rem; */
}

.bg-transparent {
  background-color: transparent;
}

/* Previous Company Dashboard Styles */
/* company Profile */
span.filepond--label-action i {
  color: white;
  font-size: 3rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rgstr-number-cust {
  -moz-appearance: textfield;
  appearance: textfield;
}

span.filepond--label-action {
  background: #8cc63f;
  width: 170px;
  height: 170px;
  display: flex;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
}

.profile-skill-prnt-flx-cust {
  display: grid;
  grid-gap: 2%;
  grid-template-columns: 32% 32% 32%;
}

.circle-owner-img-cust img {
  border: 2px solid #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.filepond--root .filepond--drop-label {
  min-height: 11.75em !important;
  background: #ffffff;
}

.profile-sumry-cust textarea {
  border: 1px solid #707070;
  background-color: #fff;
  height: 80px;
  border-radius: 12px;
  width: 100%;
  resize: none;
  padding: 8px 0 8px 16px;
  outline: none;
}

.see-file button {
  font-size: 14px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.see-file-alls div span {
  font-size: 10px;
  font-family: "Poppins-Regular";
}

.see-file-alls p {
  color: #00a297;
  font-size: 13px;
}

.profile-descrption-cust textarea {
  border: 1px solid #707070;
  background-color: #fff;
  height: 160px;
  border-radius: 12px;
  width: 100%;
  resize: none;
  padding: 8px 0 8px 16px;
  outline: none;
}

.filepond--credits {
  display: none;
}

.individual-box-background-padding-border-radius {
  padding: 1.5rem;
  background: #e8e8e8;
  border-radius: 0.625rem;
  height: max-content;
}

.individual-box-inner-box-padding {
  padding: 0.8rem 1.1rem;
}

.services-individual-tag {
  border: 1px solid #70707079;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 0.2rem 0.7rem;
}

.individual-services-circle-width {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.35rem;
}

.services-individual-tagtext {
  font-size: 14px;
  font-weight: 300;
}

.individual-services-circle-skyblue {
  background-color: #00dafa;
}

.individual-services-circle-purple {
  background-color: #948dff;
}

.individual-services-circle-pink {
  background-color: #e12d75;
}

.individual-services-circle-green {
  background-color: #30d536;
}

.update-btn-individual-padding {
  padding: 0.5rem 1.4rem;
}

.update-btn-individual-border-radius {
  border-radius: 0.438rem;
}

.update-btn-individual-background {
  background-color: #8cc63f;
}

.weekdays-btn {
  min-width: 135px;
  padding: 0.3rem 0;
  border: 1px solid #8cc63f;
  color: #8cc63f;
  background: transparent;
  border-radius: 5px;
}

.weekdays-btn-and-time-parent {
  gap: 1rem;
}

.weekdays-time-parent {
  background-color: #e8e8e8;
  border-radius: 3px;
  padding: 0 0.5rem;
}

.weekdays-time-input {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  outline: none;
  width: 5rem;
  text-align: center;
  font-size: 13px;
}

.weekdays-btn-and-time-inner,
.weekdays-time-parent {
  display: flex;
}

.weekdays-time-parent {
  align-items: center;
}

.am-pm-font-size-individual {
  font-size: 0.625rem;
  color: #646464;
}

.To-Time {
  padding: 0 1rem;
  color: #646464;
  font-size: 15px;
}

.weekdays-btn-and-time-inner {
  gap: 0.5rem;
}

.visibleflex {
  display: flex !important;
}

.hiddennone {
  display: none !important;
}

.buttonactiveindividual {
  background: #8cc63f;
  color: #fff;
}

.plus-add-icon-individual {
  background: #8cc63f;
  color: #fff;
  font-size: 18px;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ______________________________ */
.Support-Worker-Profile li {
  height: 60px;
  width: 60px;
  border: 1px solid #707070;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}

body.modal-open .chat-right {
  z-index: -1;
}

.individual-box-background-padding-border-radius-Rates {
  padding: 1.5rem;
  background: #e8e8e8;
  border-radius: 0.625rem;
  height: max-content;
}

.individual-Weekdays {
  display: grid;
  grid-template-columns: 33% 34% 33%;
}

.individual-Saturday {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.individual-Saturday li input {
  width: 95%;
  border: 1px solid #707070;
  border-radius: 5px;
}

.individual-location {
  border: 1px solid #707070;
  border-radius: 5px;
  width: 100%;
}

.individiual input[type="checkbox"] {
  position: relative;
  width: 35px;
  background: gainsboro;
  appearance: none;
  -webkit-appearance: none;
  height: 19px;
  background: #efefef;
  outline: none;
  border-radius: 30px;
  box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
  transition: 0.5s;
  cursor: pointer;
}

.individiual input[type="checkbox"]:checked {
  background: var(--theme-green);
}

.scroll-show-file {
  overflow-y: scroll;
  height: 180px;
}

.individiual input[type="checkbox"]:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  left: -2px;
  background: #fff;
  box-shadow: 0px 1px 6px 1px gainsboro;
  border-radius: 50%;
  top: 2px;
  transform: scale(1.1);
  transition: 0.5s;
}

.individiual input[type="checkbox"]:checked:before {
  left: 17px;
}

.individiual-box input[type="checkbox"]:checked {
  background: var(--theme-green) !important;
}

.upload-file ::-webkit-file-upload-button {
  display: none;
}

.upload-file {
  align-items: center;
  border: 1px solid #707070;
  border-radius: 5px;
  width: 100%;
  padding: 10px 14px;
  display: flex;
  gap: 20px;
}

.upload-file i {
  color: #ffc107;
}

.upload-file input {
  color: #cecece;
}

/* .deleted-account-suppWorker h2 {
  font-size: 22px;
  font-weight: 500;
  color: black;
} */

.deleted-account-suppWorker p {
  color: #7a7a7a;
}

.deleted-account-btn {
  padding: 0.5rem 1rem;
  background: #e35252;
  border-radius: 40px;
  color: white;
  font-weight: 600;
}

.add-jobs {
  background: #e8e8e8;
  padding: 6px 19px;
  display: flex;
  gap: 1rem;
  align-items: center;
  border-radius: 8px;
  color: #646464;
}

.jobalerts label {
  color: #646464;
}

.jobalerts input {
  accent-color: #8cc63f;
}

span.up-img {
  position: absolute;
  bottom: -20px;
  font-weight: 600;
  color: #646464;
}

.mt-ui-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
}

.Accountdelationmodal input {
  width: 40%;
  display: block;
}

.Accountdelationmodal label {
  font-size: 20px;
  font-weight: 500;
  color: #464646;
}

/* ______________________________ */

/* Previous Company Dashboard Styles */

/* .img.searchLogoSW {
  width: 120px;
  background: #dbdbdb;
  padding: 10px 20px;
} */
.searchLogoSW {
  padding: 13px 20px;
  background-color: #dbdbdb;
  width: 30%;
}

.search-input {
  position: relative;
  left: 5%;
}

.searchInp input {
  outline: none;
  padding: 10px 40px 10px 50px;
}

.searchInp svg {
  left: 33%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 26px;
  color: #a8a8a8;
}

li.dashboardHover {
  width: 80px;
  transition: all 0.3s ease-in-out;
}

li.dashboardHover svg {
  transition: all 0.3s ease-in-out;
  font-size: 22px;
}

li.dashboardHover:hover svg {
  transform: translateY(-10px);
}

li.dashboardHover:hover p {
  transform: translateY(-10px);
  opacity: 1;
}

li.dashboardHover p {
  transition: all 0.3s ease-in-out;
  position: absolute;
  opacity: 0;
  width: 100%;
  font-size: 12px;
}

.sideBar {
  width: 20%;
}

.searchNav {
  width: calc(100% - 20%);
  grid-template-columns: 29% 55% 16%;
  margin-left: auto;
}

/* .wellcomeLifeBright {
  background-image: url(../img/welcomeLifeBg.png);
  background-size: cover;
  padding: 40px 20px;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  transition: all 0.2s ease-in-out;
} */
.wellcomeLifeBright:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #8bc63fb8;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.wellcomeLifeBright:hover:before {
  opacity: 1;
}

.wellcomeLifeHeading,
.lifeBrightLogo {
  z-index: 2;
  transition: all 0.2s ease-in-out;
}

.wellcomeLifeBright:hover .wellcomeLifeHeading {
  color: #fff !important;
}

.lokingForSearch {
  background-color: #eeeeee;
  margin-top: 20px;
  border-radius: 20px;
  padding: 40px 20px;
  min-height: 350px;
}

.tutorialVideosSW {
  background-color: #eeeeee;
  padding: 20px;
  border-radius: 20px;
}

.videoTutorialLink {
  display: grid;
  grid-template-columns: 18% 67% 15%;
  align-items: center;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
}

.JobsNear {
  background-color: #eeeeee;
  padding: 20px;
  border-radius: 20px;
}

/* Connection Page */
.connectionHeading {
  background: var(--theme-pinkish);
}

.searchInpSw input {
  background: #efefef;
  width: 30%;
  color: #a8a8a8;
}

.searchInpSw svg {
  right: 27.8%;
  color: #a8a8a8;
  top: 10px;
}

.connectListMainParent {
  background: var(--theme-light-grey);
  min-height: 60vh;
}

.connectionHeadings {
  background: var(--theme-green);
}

.ConnectionHeadsGrid {
  grid-template-columns: 22% 22% 22% 34%;
  text-align: center;
  padding: 15px;
}

.connectionDetails {
  background: #fff;
  border: 2px solid var(--theme-green);
}

/* Connection Page */
/* Staff Page */
.searchInpSwStaff input {
  background: #efefef;
  width: 100%;
  color: #a8a8a8;
}

.searchInpSwStaff svg {
  color: #a8a8a8;
  top: 10px;
  left: 7px;
}

.all-btn {
  color: #8cc63f;
}

.block-btn {
  color: #8cc63f;
}

/* Staff Modal Page */
.ovrlayModal::before {
  content: "";
  position: fixed;
  z-index: 20;
  height: 100%;
  width: 100vw;
  background: #0005;
  top: 0px;
  left: 0px;
}

.addStaffModal {
  left: 0;
  right: 0;
  width: 40%;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  padding: 40px;
  border-radius: 10px;
}

.addStaffModalInp label {
  width: 100%;
  display: block;
}

.addStaffModalInp select,
.addStaffModalInp input {
  width: 100%;
  padding: 5px;
  border-radius: 7px;
  box-shadow: 3px 5px 12px #0003;
  border: 1px solid #464646;
}

/* Staff Modal Page */
/* Staff Page */
/* Individual-Font-size */

.header-padding {
  padding: 13.5px 0;
}

.sidebar-padding {
  padding: 20px 0;
}

.logo-img {
  width: 35%;
}

.box-hover-to-green-bg-and-text-white,
.box-hover-to-green-bg-and-text-white .standard-btn-website,
.box-hover-to-green-bg-and-text-white .standard-btn-website {
  transition: all 0.3s ease-in-out;
}

.box-hover-to-green-bg-and-text-white:hover {
  background-color: var(--theme-green) !important;
  color: #fff !important;
}

.box-hover-to-green-bg-and-text-white:hover .standard-btn-website,
.box-hover-to-green-bg-and-text-white:hover .standard-btn-website {
  color: var(--theme-green);
  background-color: #fff;
}

.indivi-img-modal {
  width: 24%;
}

.vaccine-img {
  width: 138px;
  height: 165px;
}

.search-rev-box-img {
  width: 5.5rem;
  height: 5.5rem;
}

.SearchSection {
  position: absolute;
  top: 5rem;
  width: 96%;
  background-color: white;
  height: 27rem;
  border-radius: 5px;
  border: 1px solid #409eff;
}

.people-see-all {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  border-radius: 15%;
}

.no-of-people {
  margin-left: 13px;
  background-color: var(--theme-light-grey);
  color: #676767;
  border-radius: 30%;
  padding: 0px 10px;
}

.people {
  color: #a8a8a8;
}

.see-all {
  color: #409eff;
}

.search-sec1 {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #f2f9ff;
}

.search-sec2 {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.search-sec3 {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.name-text {
  color: #7e7c7c;
}

.designation-text {
  color: #d4d4d4;
}

/* .searchInp input:focus+.SearchSection {
  display: block;
} */

.SearchSection {
  display: block;
}

.time-inputs-container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.center-CircularProgressLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  /* This will take the full height of the viewport */
}

/* CHAT APP STYLES  */

.bg-cream {
  /* background-color: #FFFCF1; */
  background-color: #fffae9;
}

.bg-darkgreen {
  background-color: #00443f;
}

.color-darkgreen {
  color: #00443f;
}

.color-seven-zero {
  color: #707070;
}

.color-black-four-six {
  color: #464646;
}

.chatheader-bg {
  background: linear-gradient(#004540, #00a297);
}

.Magiciconbutton {
  background: linear-gradient(to right, #004540, #00a297);
  border: 2px solid #ffe1757a;
  color: #ffe175;
  min-width: 140px;
  height: 43px;
  border-radius: 30px;
  gap: 0.5rem;
}

.Magiciconbutton-pos {
  position: relative;
  left: 20%;
}

.main-content-chat-sidebar-parent {
  margin-top: 2rem;
}

.chat-app-inbox-sidebar-heading {
  margin-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.search-sidebar-chat-parent {
  padding: 0.5rem 0;
  margin-top: 1rem;
  border-top: 1px solid #ffffff7a;
  border-bottom: 1px solid #ffffff7a;
}

.search-sidebar-chat-input {
  width: 100%;
  color: #fff;
  /* padding-left: 2.25rem; */
}

.search-sidebar-chat-input::placeholder {
  color: #fff;
}

.search-icon-leftbar-chat {
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.search-sidebar-chat {
  width: 80%;
  margin: 0 auto;
}

.chatuserimg {
  width: 42px;
  height: 42px;
  object-fit: contain;
  border-radius: 50%;
}

.chat-left .css-9v9mzz {
  background-color: initial !important;
}

img.drawer-pic.css-1cpj39 {
  width: 30% !important;
  height: 100% !important;
}

/* .chat-left .css-9v9mzz img {
  display: none ;
} */
/* .chat-left  .css-1k8qsr3 {
  margin-left: initial !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
} */
.chat-left .css-1k8qsr3 svg {
  color: #ffffff;
}

.chats-parent {
  /* padding-top: 1.5rem;
  padding-bottom: 1.5rem; */
  gap: 1rem;
  /* height: 500px;
  overflow-y: auto; */
}

.chats-parent .css-vr91yf {
  overflow: overlay;
  height: 48vh !important;
}

.chats-parent .css-ahj2mt-MuiTypography-root {
  font-size: 17px !important;
  font-family: "Poppins-Regular" !important;
  color: #fffcf1 !important;
  text-decoration: none !important;
}

.chats-parent .css-bhs70i-MuiTypography-root,
.chats-parent .css-1b28k2n-MuiTypography-root,
.chats-parent .css-d1v1d3 {
  color: #afafaf;
  font-size: 13px !important;
  font-family: "Poppins-Regular" !important;
  text-decoration: none !important;
}

.chats-parent .css-1smykld {
  display: none;
}

.chats-parent .css-1ps4owl-MuiTypography-root-MuiLink-root:hover,
.chats-parent .css-1ps4owl-MuiTypography-root-MuiLink-root {
  text-decoration: none !important;
}

.chats-parent .css-1hdah9x {
  width: 100px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  padding: 0 14px;
}

.chats-parent .css-11c1y7m {
  align-items: center !important;
}

.chats-parent .css-1p0pczp-MuiDivider-root {
  display: none;
}

.chat-block-btn {
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  position: relative;
  z-index: 99;
  border-bottom: 1px solid #ffffff7a;
}

.chat-right .css-1fcd9iq {
  height: 86.2vh !important;
}

.logout-chat {
  background-image: url(../src/assets//img/bg-logout.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* width: 40%; */
  width: 6.5vw;
  margin: 0 auto;
  padding: 1.5rem 0 0.75rem 0;
  text-align: center;
  cursor: pointer;
  margin: 0 auto !important;
}

.sideBar .css-1k8qsr3>* {
  padding: 0;
}

.chats-inner-parent {
  cursor: pointer;
  /* padding-left: 1.1rem; */
  overflow: hidden;
}

/* .chats-inner-parent.chats-inner-active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 4px;
  height: 100%;
  background-color: #ffe175;
} */

.newmessageusers {
  width: 15px;
  height: 15px;
  background: #d5caa5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  right: 0;
  top: -33px;
  border-radius: 50px;
}

.chat-active {
  border-right: 4px solid #ffe175;
}

.circle-if-online.user-online {
  position: absolute;
  right: 8px !important;
}

.user-offline {
  background-color: #929292 !important;
  position: absolute;
  right: 8px !important;
}

.circle-if-online {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: #00ff11;
  position: absolute;
  bottom: 0;
  right: 0;
}

.top-gap-bcz-ofheader {
  padding-top: 4.9rem;
}

.main-chat-top-bar {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
}

.same-gap-main-chat-one {
  gap: 0.75rem;
}

.top-bar-search {
  border: 1px solid #b5b5b5;
  border-radius: 6px;
  color: #707070;
  height: 43px;
  padding: 0 2.25rem 0 1rem;
}

.top-bar-search-icon {
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  color: #707070;
}

.top-bar-search-and-setting-parent {
  gap: 1.25rem;
}

.top-bar-setting-icon {
  color: #707070;
  font-size: 27px;
}

.showing-chats-parent {
  display: flex;
  flex-direction: column-reverse;
  height: 77vh;
  overflow-y: auto;
  padding: 1rem;
  position: relative;
}

.chat-app-msg {
  padding: 1rem;
  border-radius: 18px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.showing-chats-inner-parent {
  gap: 1.5rem;
}

.type-msg-box-btm-parent {
  border-top: 1px solid #ffffff7a;
  padding: 1rem 1.5rem;
}

.type-msg-box-input {
  height: 57px;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 18px;
  padding: 0 7.25% 0 1rem;
}

.type-msg-box-btm-parent {
  gap: 1.5rem;
}

.type-msg-box-send-btn {
  border-radius: 0 18px 18px 0;
  color: #fff;
  font-size: 24px;
  width: 7%;
}

.top-of-right-side-chat {
  border-bottom: 1px solid #707070c4;
  padding: 2rem 0 1rem 1.25rem;
}

.top-of-right-side-chat-text::before {
  content: "";
  position: absolute;
  width: 26%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #00443f;
}

.middle-of-right-img {
  border: 2px solid #eeeeee;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  object-fit: contain;
}

.middle-of-right-side-profile {
  gap: 0.35rem;
}

.profile-text-para {
  padding-bottom: 0.65rem;
  width: 80%;
  border-bottom: 1px solid #707070c4;
  margin: 0 auto;
}

.middle-of-right-side-chat {
  gap: 1rem;
  padding-top: 1rem;
}

.right-side-bar-box {
  gap: 1rem;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 0.6rem 1rem;
}

.right-side-bar-boxes-parent {
  gap: 1rem;
  padding: 0 1.7rem;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.files-tab-button {
  background-color: #d6d6d6;
  color: #ffffff;
  height: 5vh;
}

.files-tab-button-active {
  background-color: #00443f !important;
}

.files-tab-content-inner {
  padding: 0.75rem 1.2rem;
  border-bottom: 1px solid #cbcbcb;
}

.icon-and-text-of-all-files {
  gap: 0.4rem;
}

.files-tab-content-parent-fixed-height-one {
  height: 11rem;
  overflow-y: auto;
}

.right-side-middle-content-fixed-height {
  overflow-y: auto;
  height: 27rem;
}

.group-add-remove-member-parent {
  padding: 0 1.75rem;
  gap: 1rem;
}

.group-add-remove-box {
  border: 1px solid #00443f;
  padding: 0.7rem 1rem;
  border-radius: 10px;
}

.chat-seeblocklist-btn-parent {
  padding: 2rem 1.5rem 0 1.5rem;
}

.unblockchat-side {
  color: #00443f;
  border-radius: 7px;
  padding: 0.35rem 0.8rem;
}

.delete-convo-parent-box {
  border: 1px solid #b5b5b5;
  border-radius: 6px;
  bottom: -7rem;
  width: 90%;
}

.delete-convo-box-btn {
  padding: 0.35rem 1rem;
  transition: all 0.3s ease-in-out;
}

.delete-convo-box-btn:hover {
  background-color: #00a297;
  color: #fff;
}

.direct-chat-parent-box {
  width: 40%;
  bottom: -2.5rem;
  border-radius: 10px;
  right: 1rem;
}

.font-size-15px {
  font-size: 15px;
}

.delete-convo-box-btn:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.delete-convo-box-btn:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal-overlay-chat-app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  /* semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-chat-app {
  width: 44vw;
  padding: 2rem 2rem;
  gap: 1rem;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.modal-file-upld-cust {
  position: absolute;
  top: 46rem;
  width: 50%;
}

.prnt-multiy-show-imgs-container {
  width: 100%;
  /*Adjust as needed */
  overflow-x: auto;
  overflow-y: hidden;
  /* Optional: hide vertical scrollbar */
  white-space: nowrap;
  /* Keep images in a single row */
}

/* .prnt-multiy-show-imgs{
  display: inline-flex;
} */
.modalcancelicon {
  top: -0.5rem;
  right: -0.5rem;
}

.chat-modal-search-input {
  border-radius: 20px;
  padding: 0 1.5rem;
  height: 45px;
  border: 1px solid #707070;
}

.direct-chat-start {
  padding: 1.2rem 0;
  border-bottom: 1px solid #707070;
}

.Start-chat-btn-modal {
  padding: 0.45rem 2.2rem;
  border-radius: 7px;
}

.fixed-height-width-img-modal {
  width: 70px;
  height: 70px;
  object-fit: contain;
  border-radius: 50%;
}

.group-modal-input {
  height: 45px;
  border: 1px solid #707070;
  border-radius: 7px;
  padding: 0 0.8rem;
}

.chat-header-msg-box-parent {
  width: 15vw;
  border: 1px solid #7070708a;
  border-radius: 4px;
  left: -8rem;
  bottom: -24.05rem;
}

.msg-and-clear-parent {
  padding: 0.3rem 0.9rem;
  border-bottom: 1px solid #7070708a;
}

.view-all-parent {
  padding: 0.3rem 0.9rem;
  border-top: 1px solid #7070708a;
}

.chat-header-open-box-main-parent {
  height: 18.1rem;
  overflow-y: auto;
}

.css-1qqxbpd>svg {
  padding: 0 !important;
}

.chat-header-img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
}

.chat-header-open-box-main-inner-parent {
  padding: 0.65rem 1rem;
  justify-content: space-between;
  border-bottom: 1px solid #7070708a;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.chat-header-open-box-main-inner-parent:hover {
  background-color: #00443f;
}

.chat-header-open-box-main-inner-parent:hover span {
  color: #fff !important;
}

.selected-member-name {
  min-width: 93px;
  height: 33px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-member-img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px dashed #f60404;
  margin-left: auto;
  margin-right: auto;
}

.selected-member-parent {
  gap: 0.3rem;
}

.adding-member-top-box {
  left: 0;
  bottom: -13.3rem;
  width: 87%;
  border: 1px solid #707070;
  border-radius: 4px;
  padding: 1rem;
  gap: 0.75rem;
  height: 13rem;
  overflow-y: auto;
}

.adding-member-img {
  width: 65px;
  height: 65px;
  object-fit: contain;
  border-radius: 50%;
}

.adding-member-top-box-inner {
  padding: 0.75rem 1.75rem;
  gap: 2rem;
  cursor: pointer;
}

.ChatMainImg {
  width: 35%;
}

.doc-size {
  width: 14px;
  height: 14px;
  object-fit: contain;
}

.same-gap-main-chat-one {
  align-items: center;
}

.chat-grid-imgs {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 32% 32% 32%;
}

.chat-uploaded-img {
  width: 140px;
  height: 100px;
  object-fit: contain;
}

.chat-sticky {
  position: sticky;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}

/* SA DEv */
.bg-highlight-color {
  background-color: #ebebebfc;
  border-radius: 9px;
}

/* CHAT APP STYLES  */

/* MODAL FILE BOX */
.modal-inner-file-box {
  border: 1px solid #707070;
  background-color: var(--theme-light-grey);
  width: 40%;
  border-radius: 4px;
  flex: 0 0 auto;
}

.modal-file-show img {
  max-width: 30px;
  min-height: 30px;
}

.modal-inner-file-box svg {
  right: 0.68em;
  top: 0.5rem;
}

.modal-file-name-cust-txt h4 {
  font-size: 14px;
}

.modal-file-name-cust-txt p {
  font-size: 12px;
}

/* MODAL FILE BOX */
/* File UploadLoader */
.linear-progress-material {
  background: #aad1f9;
  height: 10px;
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  -webkit-animation: start 0.3s ease-in;
  animation: start 0.3s ease-in;
}

.linear-progress-material.small {
  width: 100%;
  height: 2px;
}

.linear-progress-material .bar {
  position: absolute;
  background: #106cc8;
  transition: transform 0.2s linear;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.yellow-line {
  border-right: 4px solid #FFE175;
}

.linear-progress-material .bar1 {
  -webkit-animation: growBar1 2.5s infinite, moveBar1 2.5s infinite;
  animation: growBar1 2.5s infinite, moveBar1 2.5s infinite;
}

.linear-progress-material .bar2 {
  -webkit-animation: growBar2 2.5s infinite, moveBar2 2.5s infinite;
  animation: growBar2 2.5s infinite, moveBar2 2.5s infinite;
}

@-webkit-keyframes growBar1 {
  0% {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    transform: scaleX(0.1);
  }

  36.6% {
    -webkit-animation-timing-function: cubic-bezier(0.33473,
        0.12482,
        0.78584,
        1);
    animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
    transform: scaleX(0.1);
  }

  69.15% {
    -webkit-animation-timing-function: cubic-bezier(0.22573,
        0,
        0.23365,
        1.37098);
    animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
    transform: scaleX(0.83);
  }

  100% {
    transform: scaleX(0.1);
  }
}

@keyframes growBar1 {
  0% {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    transform: scaleX(0.1);
  }

  36.6% {
    -webkit-animation-timing-function: cubic-bezier(0.33473,
        0.12482,
        0.78584,
        1);
    animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
    transform: scaleX(0.1);
  }

  69.15% {
    -webkit-animation-timing-function: cubic-bezier(0.22573,
        0,
        0.23365,
        1.37098);
    animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
    transform: scaleX(0.83);
  }

  100% {
    transform: scaleX(0.1);
  }
}

@-webkit-keyframes moveBar1 {
  0% {
    left: -105.16667%;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  20% {
    left: -105.16667%;
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
  }

  69.15% {
    left: 21.5%;
    -webkit-animation-timing-function: cubic-bezier(0.30244,
        0.38135,
        0.55,
        0.95635);
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
  }

  100% {
    left: 95.44444%;
  }
}

@keyframes moveBar1 {
  0% {
    left: -105.16667%;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  20% {
    left: -105.16667%;
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
  }

  69.15% {
    left: 21.5%;
    -webkit-animation-timing-function: cubic-bezier(0.30244,
        0.38135,
        0.55,
        0.95635);
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
  }

  100% {
    left: 95.44444%;
  }
}

@-webkit-keyframes growBar2 {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.20503,
        0.05705,
        0.57661,
        0.45397);
    animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
    transform: scaleX(0.1);
  }

  19.15% {
    -webkit-animation-timing-function: cubic-bezier(0.15231,
        0.19643,
        0.64837,
        1.00432);
    animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
    transform: scaleX(0.57);
  }

  44.15% {
    -webkit-animation-timing-function: cubic-bezier(0.25776,
        -0.00316,
        0.21176,
        1.38179);
    animation-timing-function: cubic-bezier(0.25776,
        -0.00316,
        0.21176,
        1.38179);
    transform: scaleX(0.91);
  }

  100% {
    transform: scaleX(0.1);
  }
}

@keyframes growBar2 {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.20503,
        0.05705,
        0.57661,
        0.45397);
    animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
    transform: scaleX(0.1);
  }

  19.15% {
    -webkit-animation-timing-function: cubic-bezier(0.15231,
        0.19643,
        0.64837,
        1.00432);
    animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
    transform: scaleX(0.57);
  }

  44.15% {
    -webkit-animation-timing-function: cubic-bezier(0.25776,
        -0.00316,
        0.21176,
        1.38179);
    animation-timing-function: cubic-bezier(0.25776,
        -0.00316,
        0.21176,
        1.38179);
    transform: scaleX(0.91);
  }

  100% {
    transform: scaleX(0.1);
  }
}

@-webkit-keyframes moveBar2 {
  0% {
    left: -54.88889%;
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
    animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
  }

  25% {
    left: -17.25%;
    -webkit-animation-timing-function: cubic-bezier(0.31033,
        0.28406,
        0.8,
        0.73372);
    animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
  }

  48.35% {
    left: 29.5%;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
    animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
  }

  100% {
    left: 117.38889%;
  }
}

@keyframes moveBar2 {
  0% {
    left: -54.88889%;
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
    animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
  }

  25% {
    left: -17.25%;
    -webkit-animation-timing-function: cubic-bezier(0.31033,
        0.28406,
        0.8,
        0.73372);
    animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
  }

  48.35% {
    left: 29.5%;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
    animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
  }

  100% {
    left: 117.38889%;
  }
}

@-webkit-keyframes start {
  from {
    max-height: 0;
    opacity: 0;
  }

  to {
    max-height: 20px;
    opacity: 1;
  }
}

@keyframes start {
  from {
    max-height: 0;
    opacity: 0;
  }

  to {
    max-height: 20px;
    opacity: 1;
  }
}

@-webkit-keyframes end {
  from {
    max-height: 0;
    opacity: 0;
  }

  to {
    max-height: 2px;
    opacity: 1;
  }
}

@keyframes end {
  from {
    max-height: 0;
    opacity: 0;
  }

  to {
    max-height: 2px;
    opacity: 1;
  }
}

@-webkit-keyframes progressLinearMovement {
  0% {
    left: -100%;
  }

  50% {
    left: 100%;
  }

  100% {
    left: 100%;
  }
}

@keyframes progressLinearMovement {
  0% {
    left: -100%;
  }

  50% {
    left: 100%;
  }

  100% {
    left: 100%;
  }
}

/* File UploadLoader */

@media only screen and (max-width: 1600px) {

  .chats-parent .css-1hdah9x {
    height: 47px;
  }

  /* Individual Profile */
  .individual-profile-font-size {
    font-size: 14px;
  }

  .modal-file-upld-cust {
    position: absolute;
    top: 37rem;
  }

  .font-size-50px {
    font-size: 40px;
  }

  .font-size-40px {
    font-size: 33px;
  }

  .font-size-32px {
    font-size: 28px;
  }

  .font-size-16px {
    font-size: 14px;
  }

  .btn-responsive {
    font-size: 0.8rem;
  }

  .logo-img {
    width: 33%;
  }

  .font-size-17px {
    font-size: 15px;
  }

  .header-padding {
    padding: 11px 0;
  }

  li.dashboardHover svg {
    font-size: 19px;
  }

  li.dashboardHover p {
    font-size: 10px;
  }

  .searchInp svg {
    font-size: 20px;
  }

  .lokingForSearch {
    min-height: 270px;
  }

  .individual-box-background-padding-border-radius {
    padding: 0.9rem;
    border-radius: 0.425rem;
  }

  span.filepond--label-action {
    width: 120px;
    height: 120px;
  }

  .To-Time {
    padding: 0 0.5rem;
    font-size: 12px;
  }

  .weekdays-time-input {
    border-radius: 3px;
    width: 3.5rem;
    margin-right: 0.2rem;
    font-size: 12px;
  }

  .weekdays-btn {
    min-width: 115px;
    padding: 0.25rem 0;
  }

  /* Individual Profile */
  /*  */
  .searchInp input {
    font-size: 14px;
    padding: 10px 30px 10px 43px;
  }

  .ConnectionHeadsGrid {
    padding: 12px;
  }

  .deleted-account-btn {
    padding: 0.4rem 0.8rem;
    border-radius: 30px;
    font-size: 13px;
  }

  .vaccine-img {
    width: 118px;
    height: 135px;
  }

  .search-rev-box-img {
    width: 4.5rem;
    height: 4.5rem;
  }

  .search-sec1 {
    padding: 0.4rem;
  }

  .search-sec2 {
    padding: 0.4rem;
  }

  .search-sec3 {
    padding: 0.4rem;
  }

  .SearchSection {
    width: 100%;
    height: 24rem;
  }

  /*  */

  /* Chat App Responsive */
  .chat-right .css-1fcd9iq {
    height: 81.4vh !important;
  }

  .right-side-bar-box {
    gap: 10px;
    padding: 0.6rem 8px;
  }

  .chats-parent {
    justify-content: initial;
  }

  .main-content-chat-sidebar-parent {
    margin-top: 1rem;
  }

  .chats-parent .css-ahj2mt-MuiTypography-root,
  .chats-parent .css-9l3uo3 {
    font-size: 15px !important;
  }

  .chats-parent .css-vr91yf {
    overflow: overlay;
    height: 36vh !important;
  }

  .chats-parent {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    gap: 0.8rem;
    /* height: 365px; */
    height: 68vh;
  }

  .logout-chat {
    /* width: 44%; */
    width: 7.5vw;

    padding: 1.45rem 0 0.75rem 0;
  }



  .search-sidebar-chat-input {
    font-size: 13px;
  }

  .chat-app-inbox-sidebar-heading {
    margin-top: 1rem;
  }

  .showing-chats-inner-parent {
    gap: 1.2rem;
  }

  .files-tab-content-inner {
    padding: 0.55rem 1.2rem;
  }

  .top-gap-bcz-ofheader {
    padding-top: 4.4rem;
  }

  .top-bar-search {
    height: 39px;
  }

  .middle-of-right-img {
    width: 90px;
    height: 90px;
  }

  .top-of-right-side-chat {
    padding: 2rem 0 1rem 0.9rem;
  }

  .profile-text-para {
    width: 86%;
  }

  .chatuserimg,
  .chat-header-img {
    width: 36px;
    height: 36px;
  }

  .type-msg-box-input {
    height: 48px;
    border-radius: 12px;
  }

  .type-msg-box-send-btn {
    border-radius: 0 12px 12px 0;
  }

  .type-msg-box-send-btn {
    font-size: 20px;
  }

  .type-msg-box-btm-parent {
    padding: 0.75rem 1.5rem;
  }

  .right-side-middle-content-fixed-height {
    height: 21rem;
  }

  .group-add-remove-member-parent {
    padding: 0 1.4rem;
    gap: 0.8rem;
  }

  .type-msg-box-btm-parent label img {
    width: 90%;
  }

  .chat-app-msg {
    padding: 0.75rem;
    border-radius: 12px;
    padding-bottom: 3px;
  }

  .Magiciconbutton {
    min-width: 130px;
    height: 41px;
  }

  .chat-header-open-box-main-parent {
    height: 16.1rem;
  }

  .chat-header-msg-box-parent {
    bottom: -21.5rem;
  }

  .modal-chat-app {
    padding: 1.55rem 2rem;
    gap: 1.25rem;
  }

  .direct-chat-start {
    padding: 1rem 0;
  }

  .modalcancelicon {
    width: 23px;
    height: 23px;
  }

  .Start-chat-btn-modal {
    padding: 0.45rem 1.9rem;
    border-radius: 7px;
    font-size: 15px;
  }

  .fixed-height-width-img-modal {
    width: 55px;
    height: 55px;
  }

  .group-modal-input {
    height: 33px;
    font-size: 12px;
  }

  .adding-member-img {
    width: 50px;
    height: 50px;
  }

  .adding-member-top-box-inner {
    padding: 0.75rem 1.45rem;
    gap: 1.5rem;
  }

  .adding-member-top-box {
    bottom: -11.3rem;
    width: 85%;
    padding: 0.8rem;
    gap: 0.75rem;
    height: 11rem;
  }

  .files-tab-button {
    font-size: 14px;
  }

  .chat-uploaded-img {
    width: 120px;
    height: 90px;
  }

  /* Chat App Responsive */
}

@media only screen and (max-width: 1366px) {

  .css-d1v1d3,
  .sidebarusers .css-ril2h7 {
    font-size: 10px !important;
  }

  .sidebarusers.MuiBox-root.css-11c1y7m {
    height: 54px;
  }

  .see-file-alls div span {
    font-size: 7px;
  }

  .chats-parent .css-bhs70i-MuiTypography-root,
  .chats-parent .css-1b28k2n-MuiTypography-root,
  .chats-parent .css-d1v1d3 {
    font-size: 10px !important;
  }

  .see-file-alls p {
    font-size: 8px;
  }

  /* Individual Profile */
  .modal-file-upld-cust {
    position: absolute;
    top: 30rem;
  }

  .MuiBox-root.css-15i0yn5 {
    height: 55px;
  }

  .relative.mean-send-mesg input {
    padding: 16px;
  }

  .individual-profile-font-size {
    font-size: 12px;
  }

  .font-size-50px {
    font-size: 33px;
  }

  .font-size-30px {
    font-size: 21px;
  }

  .font-size-40px {
    font-size: 28px;
  }

  .font-size-32px {
    font-size: 24px;
  }

  .font-size-16px {
    font-size: 12px;
  }

  /* Individual-dashboard */
  .btn-responsive {
    font-size: 0.7rem;
  }

  .logo-img {
    width: 30%;
  }

  .font-size-17px {
    font-size: 13px;
  }

  .header-height {
    height: 3.5rem;
  }

  li.dashboardHover svg {
    font-size: 16px;
  }

  li.dashboardHover p {
    font-size: 9px;
  }

  .searchInp svg {
    font-size: 16px;
  }

  .sidebar-padding {
    padding: 14.5px 0;
  }

  .header-padding {
    padding: 4px 0;
  }

  .user-image {
    width: 40px;
    height: 40px;
  }

  .middle-of-right-side-chat {
    gap: 0.3rem;
    padding-top: 0.2rem;
  }

  .right-side-bar-boxes-parent {
    gap: 0.5rem;
    padding: 0px 0.7rem;
  }

  .logo-img {
    width: 30%;
  }

  .deleted-account-btn {
    padding: 0.3rem 0.7rem;
    border-radius: 30px;
    font-size: 11px;
  }

  .weekdays-btn {
    min-width: 100px;
    padding: 0.2rem 0;
  }

  span.filepond--label-action {
    width: 100px;
    height: 100px;
  }

  /* Individual Profile */

  /*  */
  .searchLogoSW {
    padding: 8px 14px;
  }

  .searchInp input {
    font-size: 13px;
    padding: 7px 25px 7px 40px;
  }

  .ConnectionHeadsGrid {
    padding: 9px;
  }

  .search-rev-box-img {
    width: 3.5rem;
    height: 3.5rem;
  }

  /*  */

  /* Chat App Responsive */
  .chats-parent .css-1hdah9x {
    width: 69px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
    padding: 0 8px;
  }

  .top-gap-bcz-ofheader {
    padding-top: 3.25rem;
  }

  .main-content-chat-sidebar-parent {
    margin-top: 1rem;
  }

  .search-sidebar-chat-input {
    font-size: 11px;
  }

  .chatuserimg,
  .chat-header-img {
    width: 31px;
    height: 31px;
  }

  .chats-parent {
    padding-top: 0.75rem;
    padding-bottom: 1.2rem;
    gap: 0.7rem;
    height: 65vh;
  }

  .logout-chat {
    /* width: 47%; */
    padding: 1.25rem 0 0.35rem 0;
    text-decoration: none !important;
  }

  .chat-app-msg {
    font-size: 9px;
  }

  .type-msg-box-input {
    height: 38px;
    border-radius: 10px;
    font-size: 12px;
  }

  .type-msg-box-send-btn {
    border-radius: 0 10px 10px 0;
    font-size: 18px;
  }

  .right-side-middle-content-fixed-height {
    height: 16rem;
  }

  .type-msg-box-btm-parent label img {
    width: 78%;
  }

  img.doc-size {
    width: 13px;
    height: 13px;
  }

  .middle-of-right-img {
    width: 70px;
    height: 70px;
  }

  .delete-convo-parent-box {
    bottom: -6.3rem;
  }

  .files-tab-button {
    font-size: 13px;
  }

  .font-size-10px {
    font-size: 0.525rem;
  }

  .font-size-12px {
    font-size: 9px !important;
  }

  .font-size-16px {
    font-size: 14px !important;
  }

  .modal-chat-app {
    padding: 1.25rem 1.7rem;
    gap: 1rem;
  }

  .group-modal-input {
    height: 28px;
    font-size: 11px;
  }

  .fixed-height-width-img-modal {
    width: 45px;
    height: 45px;
  }

  .Start-chat-btn-modal {
    font-size: 10px;
  }

  .selected-member-name {
    min-width: 86px;
    height: 28px;
  }

  .direct-chat-parent-box {
    width: 50%;
  }

  .font-size-15px {
    font-size: 12px;
  }

  .color-seven-zero {
    font-size: 12px;
  }

  .chat-header-open-box-main-parent {
    height: 14.1rem;
  }

  .chat-header-msg-box-parent {
    bottom: -18.65rem;
    width: 17vw;
    left: -5rem;
  }

  .chat-uploaded-img {
    width: 110px;
    height: 80px;
  }

  /* Chat App Responsive */
}

@media only screen and (max-width: 1280px) {

  .chats-parent .css-ahj2mt-MuiTypography-root,
  .chats-parent .css-9l3uo3 {
    font-size: 13px !important;
  }

  .showing-chats-parent {
    padding: 0.7rem;
  }

  .search-sidebar-chat-input {
    font-size: 10px;
  }

  .chats-parent {
    /* height: 320px; */
  }

  .chatuserimg,
  .chat-header-img {
    width: 29px;
    height: 29px;
  }

  .top-bar-search {
    height: 34px;
  }

  .logout-chat {
    /* width: 51%; */
    font-size: 12px;
  }

  .group-add-remove-box {
    padding: 0.5rem 0.8rem;
    border-radius: 10px;
  }

  .profile-text-para {
    width: 89%;
  }

  .right-side-middle-content-fixed-height {
    height: 15rem;
  }

  .top-gap-bcz-ofheader {
    padding-top: 3.1rem;
  }

  .middle-of-right-img {
    width: 64px;
    height: 64px;
  }
}

/*  */
.chat-right {
  width: 60%;
}

.chat-left {
  width: 20%;
}

.chat-main-right-side-parent {
  width: 20%;
}

.chats-parent .css-vr91yf::-webkit-scrollbar {
  width: 12px;
}

.chats-parent .css-vr91yf::-webkit-scrollbar-track {
  background: #f1f1f100;
  height: 8px;
}

.chats-parent .css-vr91yf::-webkit-scrollbar-thumb {
  background: #fffae9;
  border-radius: 6px;
  height: 8px !important;
}

/* .chats-parent .css-1wi2fos::-webkit-scrollbar-thumb:hover {
  background: #555;
  height: 8px;

} */

.chats-parent .css-vr91yf::-webkit-scrollbar-thumb:vertical {
  display: none;
}

.chats-parent .css-vr91yf:hover::-webkit-scrollbar-thumb:vertical {
  display: block;
}

.green-dot {}

.relative.mean-send-mesg {
  border-radius: 18px;
  background-color: #ffffff;
  width: 100%;
}

.relative.mean-send-mesg input {
  width: 100%;
  padding: 20px;
  padding-left: 25px;
  font-size: 14px;
  height: 20px;
  width: 100%;
  border: 1px solid #959191;
  border-radius: 40px;
}

.full__page__loader {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebarusers .css-9l3uo3 {
  color: #fffcf1;
}

.chats-inner-parent .css-1wi2fos {

  height: 65vh !important;
}

.sidebarusers .css-ril2h7,
.css-d1v1d3 {
  color: #afafaf;
  text-decoration: none;
}

@media only screen and (max-width: 1600px) {
  .font-size-15px {
    font-size: 12px;
  }
}